<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header title="Recently Created Events"/>

      <v-data-table v-if="events"
        :headers="headers"
        :items="events"
        :items-per-page="25"
        >
        <template v-slot:body="{ items }">
          <tbody>
          <tr v-for="(item, idx) in items" :key="idx" :class="getColorClass(item)">
            <td>
              <div><router-link :to="{name: 'eventmanagerView', params: {id: item.id}}">{{item.name}}</router-link></div>
              <strong class="d-block">{{item.org}}</strong>
              {{ item.email }}
              <v-icon v-if="item.remarks" x-small :title="item.remarks">fa fa-comment</v-icon>
            </td>
            <td>{{ item.from | localDate('L') }}</td>
            <td>{{ item.till | localDate('L') }}</td>
            <td style="width:100px;">
              <span>
                <strong>{{ item.seats }}</strong>x {{ item.license }}
                <div v-if="item.participant_count" class="text-muted ml-2">({{item.participant_count}} users)</div>
              </span>
            </td>
            <td>
              <v-icon v-if="item.has_slack" class="mr-2" title="Slack Integration Enabled">fab fa-slack</v-icon>
              <v-icon v-if="item.has_teams" class="mr-2" title="Microsoft Teams Integration Enabled">fa fa-users</v-icon>
            </td>
            <td>
              <span v-if="item.user_still_active">
                <v-icon small color="green">fa-check-circle</v-icon> STILL ACTIVE
              </span>
              <span v-else>
              <v-btn text color="primary" @click="remindForRenewal(item.id)"><v-icon small class="me-2">fa-envelope</v-icon> Email</v-btn> 
              <v-btn text color="primary" @click="dismiss(item.id)"><v-icon small class="me-2">fa-times</v-icon> Dismiss</v-btn>
              </span>
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Renewals",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      headers: [
        //{ text: 'Id', value: 'id', sortable: true},
        //{ text: 'Created On', value: 'created_on', sortable: true},
        { text: 'Event', value: 'email', sortable: true},
        //{ text: 'Is Published', value: 'published', sortable: true},
        { text: 'Active From', value: 'from', sortable: true},
        { text: 'Active Till', value: 'till', sortable: true},
        { text: 'License', value: 'license', sortable: true},
        //{ text: '# participants', value: 'participant_count', sortable: true},
        //{ text: '# regs', value: 'reg_count', sortable: true},
        { text: 'Options', value: 'flags', sortable: false},
        { text: 'Actions', value: 'actions', sortable: false},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.events = (await eventManagerService.getEventsUpForRenewal()).data.data;
      }
    },
    email(eventId) {
    },
    async dismiss(eventId) {
      if (confirm(`Are you sure you want to dismiss ${eventId} so it no longer shows on this list?`)) {
        const response = (await eventManagerService.markEventAsDismissedForRenewal(eventId)).data;
        this.$helpers.toastResponse(this, response, 'Action completed.');
      }
    },
    async remindForRenewal(eventId) {
      if (confirm(`Are you sure you want to remind ${eventId} by sending an email and hiding from the list?`)) {
        const response = (await eventManagerService.remindForRenewal(eventId)).data;
        this.$helpers.toastResponse(this, response, 'Action completed.');
      }
    },
    getColorClass(item) {
      if (item.user_still_active) {
        return 'light-green lighten-4';
      }
      if (item.seats >= 1000) {
        return 'orange lighten-2';
      }
      if (item.seats >= 500) {
        return 'orange lighten-3';
      }
      if (item.seats >= 100) {
        return 'orange lighten-4';
      }
    }
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Events up for Renewal', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

